import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { auth } from '../../firebase'; // Import Firebase auth

const InternalMessagesList = ({ messages, onSelectThread, selectedThreadId, onArchiveMessage, onArchiveThread }) => {
  const [currentUserEmail, setCurrentUserEmail] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUserEmail(user.email);
      } else {
        setCurrentUserEmail('');
      }
    });

    return () => unsubscribe();
  }, []);

  // Function to get the other person's name in the conversation
  const getConversationName = (message) => {
    return message.sender === currentUserEmail ? message.recipientName : message.senderName;
  };

  // Function to truncate the message content for the snippet
  const truncateContent = (content, maxLength = 60) => {
    if (content.length <= maxLength) return content;
    return content.substr(0, maxLength - 3) + '...';
  };

  return (
    <div className="internal-messages-list dark:bg-gray-800 h-full">
      <h2 className="text-xl font-semibold p-4 bg-gray-50 border-b border-gray-200">Conversations</h2>
      {messages.length === 0 ? (
        <p className="p-4 text-gray-500">No messages found.</p>
      ) : (
        <ul className="divide-y divide-gray-200">
          {messages.map(message => (
            <li 
              key={message.id} 
              className={`cursor-pointer hover:bg-gray-50 transition duration-150 ease-in-out ${selectedThreadId === (message.threadId || message.id) ? 'bg-blue-50' : ''}`}
            >
              <div className="p-4 space-y-2">
                <div className="flex justify-between items-start">
                  <div className="flex-1" onClick={() => onSelectThread(message)}>
                    <span className="font-medium text-gray-900">{getConversationName(message)}</span>
                    <span className="ml-2 text-sm text-gray-500">{message.subject}</span>
                  </div>
                  <span className="text-xs text-gray-400">
                    {message.timestamp && message.timestamp.toDate 
                      ? message.timestamp.toDate().toLocaleString() 
                      : 'Date unavailable'}
                  </span>
                </div>
                <p className="text-sm text-gray-600">{truncateContent(message.content)}</p>
                <div className="flex justify-end space-x-2">
                  <button 
                    onClick={() => onArchiveMessage(message.id)} 
                    className="text-red-500 hover:text-red-700"
                  >
                    Archive Message
                  </button>
                  {message.threadId && (
                    <button 
                      onClick={() => onArchiveThread(message.threadId)} 
                      className="text-red-500 hover:text-red-700"
                    >
                      Archive Thread
                    </button>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

InternalMessagesList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    sender: PropTypes.string.isRequired,
    senderName: PropTypes.string.isRequired,
    recipientName: PropTypes.string.isRequired,
    timestamp: PropTypes.object,
    threadId: PropTypes.string,
    content: PropTypes.string.isRequired,
  })).isRequired,
  onSelectThread: PropTypes.func.isRequired,
  selectedThreadId: PropTypes.string,
  onArchiveMessage: PropTypes.func.isRequired,
  onArchiveThread: PropTypes.func.isRequired,
};

export default InternalMessagesList;
