import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, where, orderBy, doc, updateDoc, writeBatch, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import InternalMessagesList from '../lists/InternalMessagesList';
import InternalMessagesThread from '../viewers/InternalMessagesThread';
import ComposeInternalMessage from '../messages/ComposeInternalMessage';

const InternalMessagesDashboard = () => {
  const [messages, setMessages] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchMessages = async () => {
    try {
      const q = query(
        collection(db, 'internalMessages'),
        where('archived', '==', false),
        orderBy('timestamp', 'desc')
      );
  
      // Listen for real-time updates
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const fetchedMessages = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log('Fetched messages:', fetchedMessages);
        setMessages(fetchedMessages);
      });
  
      // Cleanup the listener on component unmount
      return () => unsubscribe();
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  const handleThreadSelect = async (message) => {
    setLoading(true);
    console.log('Selected message:', message);
    try {
      const threadQuery = query(
        collection(db, 'internalMessages'),
        where('threadId', '==', message.threadId || message.id),
        orderBy('timestamp', 'asc')
      );
      const threadSnapshot = await getDocs(threadQuery);
      const threadMessages = threadSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log('Fetched thread messages:', threadMessages);
      setSelectedThread(threadMessages.length > 0 ? threadMessages : [message]);
    } catch (error) {
      console.error('Error fetching thread:', error);
      setSelectedThread([message]);
    } finally {
      setLoading(false);
    }
  };

  const handleMessageSent = () => {
    fetchMessages();
    if (selectedThread && selectedThread.length > 0) {
      handleThreadSelect(selectedThread[0]);
    }
  };

  const handleArchiveMessage = async (messageId) => {
    console.log('Archiving message with ID:', messageId); // Debugging log
    try {
      const messageRef = doc(db, 'internalMessages', messageId);
      await updateDoc(messageRef, { archived: true });
      console.log('Message archived successfully'); // Debugging log
      fetchMessages();
    } catch (error) {
      console.error('Error archiving message:', error);
    }
  };

  const handleArchiveThread = async (threadId) => {
    console.log('Archiving thread with ID:', threadId); // Debugging log
    try {
      const threadQuery = query(
        collection(db, 'internalMessages'),
        where('threadId', '==', threadId)
      );
      const threadSnapshot = await getDocs(threadQuery);
      const batch = writeBatch(db);
      threadSnapshot.docs.forEach(doc => {
        batch.update(doc.ref, { archived: true });
      });
      await batch.commit();
      console.log('Thread archived successfully'); // Debugging log
      fetchMessages();
    } catch (error) {
      console.error('Error archiving thread:', error);
    }
  };

  if (loading) {
    return <div className="flex dark:bg-gray-800 justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  return (
    <div className="internal-messages-dashboard bg-gray-100 dark:bg-gray-800 min-h-screen p-6">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Internal Messages Dashboard</h1>
        <div className="dashboard-content flex flex-col md:flex-row bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="w-full md:w-1/3 border-r border-gray-200">
            <InternalMessagesList 
              messages={messages} 
              onSelectThread={handleThreadSelect} 
              selectedThreadId={selectedThread && selectedThread.length > 0 ? (selectedThread[0].threadId || selectedThread[0].id) : null}
              onArchiveMessage={handleArchiveMessage} // Pass the function as a prop
              onArchiveThread={handleArchiveThread} // Pass the function as a prop
            />
          </div>
          <div className="w-full md:w-2/3 flex flex-col">
            <InternalMessagesThread thread={selectedThread} />
            <ComposeInternalMessage 
              onMessageSent={handleMessageSent} 
              replyToThread={selectedThread && selectedThread.length > 0 ? selectedThread[0] : null}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalMessagesDashboard;
