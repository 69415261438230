import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useVitals } from '../../hooks/useVitals'; // Import the useVitals hook
import PatientInformation from './PatientInformation';
import MedicalRecords from './MedicalRecords';
import AppointmentSchedule from './AppointmentSchedule';
import BillingAndPayments from './BillingAndPayments';
import TreatmentPlans from './TreatmentPlans';
import PatientNotes from './PatientNotes';
import PrescriptionManagement from './PrescriptionManagement';
import AdjustmentHistory from './AdjustmentHistory';
import PatientProgress from '../charts/PatientProgress';
import VitalsGraph from '../charts/VitalsGraph';
import AppointmentHistoryCalendar from './AppointmentHistoryCalendar'; 
import { Breadcrumb } from 'antd';
import { IdcardOutlined, TeamOutlined, EditOutlined, HeartOutlined, FolderOutlined, MedicineBoxOutlined, FileDoneOutlined } from '@ant-design/icons';


const Dashboard = () => {
  const { id } = useParams();
  const [patient, setPatient] = useState(null);
  const [loading, setLoading] = useState(true);
  const vitals = useVitals(id); // Use the useVitals hook

  useEffect(() => {
    const fetchPatient = async () => {
      try {
        const docRef = doc(db, 'patients', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setPatient({ ...docSnap.data(), id: docSnap.id });
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching patient:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPatient();
  }, [id]);

  const handleSave = async (updatedPatient) => {
    try {
      const docRef = doc(db, 'patients', id);
      await updateDoc(docRef, updatedPatient);
      setPatient(updatedPatient);
    } catch (error) {
      console.error('Error updating patient:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const menuItems = [
    {
      key: '1',
      label: (
        <Link to={`/view-forms/${id}`}><FileDoneOutlined /> View Forms</Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to={`/upload-medical-records/${id}`}><FolderOutlined /> Medical Records</Link>
      ),
    },
    {
      key: '3',
      label: (
        <Link to={`/patient-notes/${id}`}><EditOutlined /> Patient Notes</Link>
      ),
    },
    {
      key: '4',
      label: (
        <Link to={`/vitals/${id}`}><HeartOutlined /> Vitals</Link>
      ),
    },
    {
      key: '5',
      label: (
        <Link to={`/prescription-management/${id}`}><MedicineBoxOutlined /> Prescriptions</Link>
      ),
    },
  ];

  return (
    <div className="p-4 md:p-6 bg-white dark:bg-gray-900 rounded-lg shadow-md max-w-7xl mx-auto">
      <Breadcrumb
        className="text-xl font-bold dark:text-white"
        items={[
          {
            title: (
              <Link to={`/patients`} className="flex items-center dark:text-gray-400">
                <TeamOutlined style={{ fontSize: '1.25rem' }} /> <span className="text-xl">Patient List</span>
              </Link>
            ),
          },
          {
            title: (
              <Link to={`/dashboard/${id}`} className="flex items-center">
                <IdcardOutlined style={{ fontSize: '1.50rem' }} className='text-indigo-900 dark:text-indigo-300' /> <span className="text-2xl text-indigo-900 dark:text-indigo-300">Dashboard</span>
              </Link>
            ),
            menu: { items: menuItems },
          },
        ]}
      />

      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 grid-rows-auto">
        <div className="bg-white dark:bg-gray-800 p-4 rounded-md shadow-md">
          <PatientInformation patient={patient} handleSave={handleSave} />
        </div>
        <div className="bg-white dark:bg-gray-800 p-4 rounded-md shadow-md lg:col-span-2">
          <VitalsGraph vitals={vitals} />
        </div>
        <div className="bg-white dark:bg-gray-800 p-4 rounded-md shadow-md">
          <AppointmentHistoryCalendar patientId={id} />
        </div>
        <div className="bg-white dark:bg-gray-800 p-4 rounded-md shadow-md lg:col-span-2">
          <PatientProgress />
        </div>
        <div className="bg-white dark:bg-gray-800 p-4 rounded-md shadow-md">
          <PrescriptionManagement />
        </div>
        <div className="bg-white dark:bg-gray-800 p-4 rounded-md shadow-md sm:col-span-2 lg:col-span-1">
          <TreatmentPlans />
        </div>
        <div className="bg-white dark:bg-gray-800 p-4 rounded-md shadow-md sm:col-span-2 lg:col-span-1">
          <MedicalRecords />
        </div>
        <div className="bg-white dark:bg-gray-800 p-4 rounded-md shadow-md">
          <AppointmentSchedule />
        </div>
        <div className="bg-white dark:bg-gray-800 p-4 rounded-md shadow-md">
          <BillingAndPayments />
        </div>
        <div className="bg-white dark:bg-gray-800 p-4 rounded-md shadow-md">
          <AdjustmentHistory />
        </div>
        <div className="bg-white dark:bg-gray-800 p-4 rounded-md shadow-md lg:col-span-3">
          <PatientNotes />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;