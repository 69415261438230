import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../../firebase';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const PatientInformation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [patient, setPatient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [showAllInfo, setShowAllInfo] = useState(false);
  const [formData, setFormData] = useState({});
  const [photoFile, setPhotoFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const cropperRef = useRef(null);

  useEffect(() => {
    const fetchPatient = async () => {
      try {
        const docRef = doc(db, "patients", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setPatient(docSnap.data());
          setFormData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching patient:", error);
      }
      setLoading(false);
    };

    fetchPatient();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setPhotoFile(e.target.files[0]);
    setShowCropper(true);
  };

  const handleCrop = useCallback(() => {
    const cropper = cropperRef.current.cropper;
    cropper.getCroppedCanvas().toBlob((blob) => {
      setCroppedImage(blob);
    });
  }, []);

  const handleSave = async () => {
    try {
      if (croppedImage) {
        const storage = getStorage();
        const storageRef = ref(storage, `patients/${id}/${photoFile.name}`);
        await uploadBytes(storageRef, croppedImage);
        const photoUrl = await getDownloadURL(storageRef);
        formData.photoUrl = photoUrl;
      }
      const docRef = doc(db, "patients", id);
      await updateDoc(docRef, formData);
      setPatient(formData);
      setEditing(false);
    } catch (error) {
      console.error("Error updating patient:", error);
    }
  };

  const handleBackClick = () => {
    navigate('/patients');
  };

  const toggleShowAllInfo = () => {
    setShowAllInfo(!showAllInfo);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 bg-white rounded-lg dark:bg-gray-800 shadow-md max-w-md mx-auto">
      <h1 className="text-2xl font-bold text-center mb-4">Patient Information</h1>
      <div className="flex items-center mb-4">
        {patient.photoUrl && (
          <img src={patient.photoUrl} alt="Patient" className="w-16 h-16 rounded-full mr-4" />
        )}
        <div>
          <p className="text-xl font-semibold">{patient.firstName} {patient.lastName}</p>
          <p className="text-gray-600">{patient.email}</p>
          {patient && (
        <p className="text-sm text-gray-500 mb-4">Medical Record Number: {patient.medicalRecordNumber || 'Not Assigned'}</p>
      )}
        </div>
      </div>
      {editing ? (
        <>
          <div>
            <label htmlFor="photoUrl" className="block text-sm font-medium text-gray-700">Upload Photo</label>
            <input
              type="file"
              id="photoFile"
              name="photoFile"
              onChange={handleFileChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
            {showCropper && photoFile && (
              <Cropper
                key={photoFile.name}
                src={URL.createObjectURL(photoFile)}
                style={{ height: 400, width: '100%' }}
                initialAspectRatio={1}
                aspectRatio={1}
                guides={false}
                crop={handleCrop}
                ref={cropperRef}
              />
            )}
          </div>          
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="age" className="block text-sm font-medium text-gray-700">Age</label>
            <input
              type="number"
              id="age"
              name="age"
              value={formData.age}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="gender" className="block text-sm font-medium text-gray-700">Gender</label>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            >
              <option value="">Select</option>
              <option value="Cisgender Male">Cisgender Male (Cis Male)</option>
              <option value="Cisgender Female">Cisgender Female (Cis Female)</option>
              <option value="Transgender Male">Transgender Male (Trans Male)</option>
              <option value="Transgender Female">Transgender Female (Trans Female)</option>
              <option value="Non-Binary">Non-Binary</option>
              <option value="Genderqueer">Genderqueer</option>
              <option value="Genderfluid">Genderfluid</option>
              <option value="Agender">Agender</option>
              <option value="Two-Spirit">Two-Spirit</option>
              <option value="Intersex">Intersex</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div>
            <label htmlFor="contact" className="block text-sm font-medium text-gray-700">Contact</label>
            <input
              type="text"
              id="contact"
              name="contact"
              value={formData.contact}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700">Date of Birth</label>
            <input
              type="date"
              id="dateOfBirth"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="emergencyContactName" className="block text-sm font-medium text-gray-700">Emergency Contact Name</label>
            <input
              type="text"
              id="emergencyContactName"
              name="emergencyContactName"
              value={formData.emergencyContactName}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="emergencyContactPhone" className="block text-sm font-medium text-gray-700">Emergency Contact Phone</label>
            <input
              type="text"
              id="emergencyContactPhone"
              name="emergencyContactPhone"
              value={formData.emergencyContactPhone}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="medicalHistory" className="block text-sm font-medium text-gray-700">Medical History</label>
            <textarea
              id="medicalHistory"
              name="medicalHistory"
              value={formData.medicalHistory}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="currentMedications" className="block text-sm font-medium text-gray-700">Current Medications</label>
            <textarea
              id="currentMedications"
              name="currentMedications"
              value={formData.currentMedications}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="allergies" className="block text-sm font-medium text-gray-700">Allergies</label>
            <textarea
              id="allergies"
              name="allergies"
              value={formData.allergies}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="primaryCarePhysician" className="block text-sm font-medium text-gray-700">Primary Care Physician</label>
            <input
              type="text"
              id="primaryCarePhysician"
              name="primaryCarePhysician"
              value={formData.primaryCarePhysician}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="insuranceProvider" className="block text-sm font-medium text-gray-700">Insurance Provider</label>
            <input
              type="text"
              id="insuranceProvider"
              name="insuranceProvider"
              value={formData.insuranceProvider}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="insurancePolicyNumber" className="block text-sm font-medium text-gray-700">Insurance Policy Number</label>
            <input
              type="text"
              id="insurancePolicyNumber"
              name="insurancePolicyNumber"
              value={formData.insurancePolicyNumber}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="reasonForVisit" className="block text-sm font-medium text-gray-700">Reason for Visit</label>
            <textarea
              id="reasonForVisit"
              name="reasonForVisit"
              value={formData.reasonForVisit}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <div>
            <label htmlFor="preferredAppointmentTime" className="block text-sm font-medium text-gray-700">Preferred Appointment Time</label>
            <select
              id="preferredAppointmentTime"
              name="preferredAppointmentTime"
              value={formData.preferredAppointmentTime}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            >
              <option value="">Select</option>
              <option value="Morning">Morning</option>
              <option value="Afternoon">Afternoon</option>
              <option value="Evening">Evening</option>
            </select>
          </div>
          <div>
            <label htmlFor="notes" className="block text-sm font-medium text-gray-700">Notes</label>
            <textarea
              id="notes"
              name="notes"
              value={formData.notes}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
            />
          </div>
          <button
            onClick={handleSave}
            className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
          >
            Save
          </button>
        </>
      ) : (
        <>
          <p>First Name: {patient.firstName}</p>
          <p>Last Name: {patient.lastName}</p>
          <p>Date of Birth: {patient.dateOfBirth}</p>
          {/* Display limited patient details */}
          {showAllInfo && (
            <>
              <p>Age: {patient.age}</p>
              <p>Gender: {patient.gender}</p>
              <p>Contact: {patient.contact}</p>
              <p>Address: {patient.address}</p>
              <p>Date of Birth: {patient.dateOfBirth}</p>
              <p>Emergency Contact Name: {patient.emergencyContactName}</p>
              <p>Emergency Contact Phone: {patient.emergencyContactPhone}</p>
              <p>Medical History: {patient.medicalHistory}</p>
              <p>Current Medications: {patient.currentMedications}</p>
              <p>Allergies: {patient.allergies}</p>
              <p>Primary Care Physician: {patient.primaryCarePhysician}</p>
              <p>Insurance Provider: {patient.insuranceProvider}</p>
              <p>Insurance Policy Number: {patient.insurancePolicyNumber}</p>
              <p>Reason for Visit: {patient.reasonForVisit}</p>
              <p>Preferred Appointment Time: {patient.preferredAppointmentTime}</p>
              {/* Add remaining patient details */}
            </>
          )}
          <button
            onClick={toggleShowAllInfo}
            className="w-full px-4 py-2 font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-4"
          >
            {showAllInfo ? 'Show Less Information' : 'Show All Information'}
          </button>
          <button
            onClick={() => setEditing(true)}
            className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
          >
            Edit
          </button>
        </>
      )}
    </div>
  );
};

export default PatientInformation;