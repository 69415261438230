// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import SignUp from './components/auth/SignUp';
import SignIn from './components/auth/SignIn';
import ForgotPassword from './components/auth/ForgotPassword';
import FinishSignUp from './components/auth/FinishSignUp';
import Dashboard from './components/pages/Dashboard';
import PatientList from './components/lists/PatientList';
import OnboardPatient from './components/pages/OnboardPatient';
import PatientInformationPage from './components/pages/PatientInformationPage';
import Schedule from './components/pages/Schedule';
import CheckInPage from './components/pages/CheckInPage';
import VitalsPage from './components/pages/VitalsPage';
import UploadMedicalRecords from './components/pages/UploadMedicalRecords';
import MedicalRecordsList from './components/lists/MedicalRecordsList';
import MedicalRecordViewer from './components/viewers/MedicalRecordViewer';
import EditProfile from './components/pages/EditProfile';
import MessageCenter from './components/pages/MessageCenter';
import PatientSignUp from './components/auth/PatientSignUp';
import PatientSignIn from './components/auth/PatientSignIn';
import PatientNotesEditor from './components/pages/PatientNotesEditor'; 
import Navbar from './components/common/Navbar';
import PublicNavbar from './components/common/PublicNavbar'; // Import the PublicNavbar
import { AuthProvider, useAuth } from './context/AuthContext'; 
import ProtectedRoute from './ProtectedRoute';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db, auth } from './firebase';
import Footer from './components/common/Footer';
import InternalMessagesDashboard from './components/pages/InternalMessagesDashboard';
import { getToken } from 'firebase/messaging';
import { messaging } from './firebase'; 
import Notifications from './components/pages/Notifications'; 
import PrescriptionManagementPage from './components/pages/PrescriptionManagementPage';
import AskQuestion from './components/pages/AskQuestion';
import AppointmentHistory from './components/pages/AppointmentHistoryCalendar';
import PatientInformation from './components/pages/PatientInformationPage';
import MultiStepForm from './components/forms/MultiStepForm';
import FormViewerPage from './components/viewers/FormViewerPage'
import ContactUs from './components/pages/ContactUs';
import StaffSchedule from './components/pages/StaffSchedule';
import Settings from './components/pages/Settings';
import { ThemeProvider } from './context/ThemeContext'
import PricingPage from './components/pages/PricingPage';
import AboutUs from './components/pages/AboutUs';
import StaffScheduler from './components/pages/StaffScheduler';

const Home = () => {
  const { currentUser } = useAuth();
  return currentUser ? <Navigate to="/patients" /> : <Navigate to="/signin" />;
};

const AppContent = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const [role, setRole] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  // const [profilePic, setProfilePic] = useState('')
  const [currentUserData, setCurrentUserData] = useState({});

  const fetchUserRole = async (user) => {
    const docRef = doc(db, 'users', user.uid); // Use user ID instead of email
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setRole(docSnap.data().role);
    }
  };
  
  const fetchCurrentUserData = async (user) => {
    const docRef = doc(db, 'users', user.uid);
    const docSnap = await (getDoc(docRef));
    if (docSnap.exists()){
      setCurrentUserData(docSnap.data());
      // console.log('currentUserData', docSnap.data());
    }
    else {
      console.log('No current user data set.');
    }
  } 

  // const fetchUserProfilePic = async (user) => {
  //   const docRef = doc(db, 'users', user.uid);
  //   const docSnap = await getDoc(docRef);
  //   if (docSnap.exists()) {
  //     setProfilePic(docSnap.data().photoURL);
  //   } else {
  //     console.log('No such document!');
  //   }
  // };
  
  useEffect(() => {
    if (currentUser) {
      console.log('Current User:', currentUser);
      // fetchUserProfilePic(currentUser);
      fetchCurrentUserData(currentUser);
    }
  }, [currentUser]);

  const storeToken = async (userId, token) => {
    console.log('Storing token for user:', userId);
    console.log('Token:', token);
    try {
      await setDoc(doc(db, 'users', userId), { fcmToken: token }, { merge: true });
      console.log('Token stored successfully');
    } catch (error) {
      console.error('Error storing token:', error);
    }
  };

  const handleNotifications = () => {
    setUnreadCount(0);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserRole(user);
      } else {
        setRole('');
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      {currentUser ? <Navbar /> : <PublicNavbar />} {/* Conditionally render Navbar */}
      
      <div></div>

      <div className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/patient-signup" element={<PatientSignUp />} />
          <Route path="/patient-signin" element={<PatientSignIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/finish-signup" element={<FinishSignUp />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/dashboard/:id" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/patients" element={<ProtectedRoute><PatientList currentUser={currentUser}/></ProtectedRoute>} />
          <Route path="/onboard-patient" element={<ProtectedRoute><OnboardPatient /></ProtectedRoute>} />
          <Route path="/patient/:id" element={<ProtectedRoute><PatientInformationPage /></ProtectedRoute>} />
          <Route path="/schedule" element={<ProtectedRoute><Schedule /></ProtectedRoute>} />
          <Route path="/checkin" element={<ProtectedRoute><CheckInPage /></ProtectedRoute>} />
          <Route path="/messages" element={<ProtectedRoute><MessageCenter /></ProtectedRoute>} />
          <Route path="/internal-messages" element={<ProtectedRoute><InternalMessagesDashboard /></ProtectedRoute>} />
          <Route path="/vitals/:id" element={<ProtectedRoute><VitalsPage /></ProtectedRoute>} />
          <Route path="/upload-medical-records/:id" element={<ProtectedRoute><UploadMedicalRecords /></ProtectedRoute>} />
          <Route path="/medical-records" element={<ProtectedRoute><MedicalRecordsList /></ProtectedRoute>} />
          <Route path="/view-medical-record/:patientId/:recordId" element={<MedicalRecordViewer />} />
          <Route path="/profile/:id" element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />
          <Route path="/staff-schedule" element={<ProtectedRoute><StaffSchedule role={role} /></ProtectedRoute>} />
          <Route path="/patient-notes/:id" element={<ProtectedRoute><PatientNotesEditor /></ProtectedRoute>} />
          <Route path="/notifications" element={<Notifications notifications={notifications} handleNotifications={handleNotifications} />} />
          <Route path="/prescription-management/:id" element={<ProtectedRoute><PrescriptionManagementPage /></ProtectedRoute>} />
          <Route path="/ask-question" element={<ProtectedRoute><AskQuestion currentUserData={currentUserData} /></ProtectedRoute>} />
          <Route path="/appointment-history/:id" element={<ProtectedRoute><AppointmentHistory /></ProtectedRoute>} />
          <Route path="patient-information/:id" element={<ProtectedRoute><PatientInformation /></ProtectedRoute>} />
          <Route path="/patient-forms/:patientId" element={<ProtectedRoute><MultiStepForm /></ProtectedRoute>} />
          <Route path="/view-forms/:patientId" element={<ProtectedRoute><FormViewerPage /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
          <Route path="/staff-scheduler" element={<ProtectedRoute><StaffScheduler /></ProtectedRoute>} />
          {/* Catch-all route for undefined paths */}
          <Route path="*" element={<Navigate to="/" />} /> {/* Redirect to home or a 404 page */}
        </Routes>
      </div>
      <Footer /> {/* Always show the footer */}
    </div>
  );
};

const App = () => (
  <AuthProvider>
    <ThemeProvider>
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  </AuthProvider>
);

export default App;