import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust based on your Firebase setup
import jsPDF from 'jspdf';
import { Breadcrumb } from 'antd';
import { EditOutlined, HeartOutlined, IdcardOutlined, FileDoneOutlined, FolderOutlined, MedicineBoxOutlined, TeamOutlined, FileAddOutlined } from '@ant-design/icons';

const FormViewerPage = () => {
  const { patientId } = useParams(); 
  const [forms, setForms] = useState([]);
  const navigate = useNavigate(); 

  const effects = [
    { category: 'Does this cause you to be:', options: ['Moody', 'Irritable', 'Interrupt Sleep', 'Restricted on Daily Activities'] },
    { category: 'Does this affect your work:', options: ['Decision Making', 'Poor Attitude', 'Decreased Productivity', 'Exhausted at End of Day'] },
    { category: 'Does this affect your life:', options: ['Lose Patience with Spouse or Children', 'Restricts Household Duties', 'Hinders Ability to Exercise or Participate in Sports', 'Interferes with Ability to Participate in Hobbies'] }
  ];

  useEffect(() => {
    const fetchForms = async () => {
      const formsCollection = collection(db, 'patients', patientId, 'forms');
      const formSnapshot = await getDocs(formsCollection);
      const formData = formSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setForms(formData);
    };

    fetchForms();
  }, [patientId]);

  // Generate PDF with styled content
  const exportToPDF = (form) => {
    const doc = new jsPDF();
    doc.setFontSize(20);
    doc.setTextColor(33, 37, 41);
    doc.text(`Patient Form: ${form.title}`, 10, 10);
    doc.setFontSize(12);
    doc.setTextColor(100);
  
    let yPosition = 30;
  
    // Define the page height, margins, and text wrapping
    const pageHeight = doc.internal.pageSize.height;
    const margin = 10;
  
    // Function to handle text wrapping and adding new pages if needed
    const addLine = (question, answer) => {
      const wrappedText = doc.splitTextToSize(`${question}: ${answer}`, 180); // Adjust width if needed
  
      wrappedText.forEach(line => {
        if (yPosition + 10 > pageHeight - margin) { // Check if we need a new page
          doc.addPage();
          yPosition = margin;
        }
        doc.text(line, 10, yPosition);
        yPosition += 10;
      });
    };

    // Function to add severity scale
    const addSeverityScale = (severity) => {
      if (!severity || severity < 1 || severity > 10) {
        severity = 1; // Default to 1 if severity is out of range or missing
      }
      
      const maxWidth = 100; // Total width of the scale
      const filledWidth = (severity / 10) * maxWidth; // Scale the filled portion

      // Check if new page is needed
      if (yPosition + 12 > pageHeight - margin) {
        doc.addPage();
        yPosition = margin;
      }

      // Draw the empty scale (gray background)
      doc.setDrawColor(150); // Gray color
      doc.rect(10, yPosition, maxWidth, 8, 'F');

      // Draw the filled scale (blue foreground)
      doc.setDrawColor(0, 0, 255); // Blue color
      doc.rect(10, yPosition, filledWidth, 8, 'F');

      yPosition += 12; // Add space for the next line
    };

    const addSectionSeparator = () => {
      doc.setDrawColor(128, 0, 128); // Set color to purple (RGB: 128, 0, 128)
      doc.setLineWidth(1.5); // Thickness of the line
      doc.line(10, yPosition, 200, yPosition); // Draw line from x=10 to x=200 at the current y position
      yPosition += 10; // Add space below the line
    };

     // Iterate over the form fields to find and render Severity scales
    Object.keys(form).forEach((field) => {
      if (field.includes('Severity')) {
        const fieldName = field.replace('Severity', ' Severity'); // Human-readable label
        const severity = form[field]; // Set severity equal to the form's field value

        if (severity !== undefined) {
          addLine(fieldName, severity || 'N/A'); // Add the severity as a line of text
          addSeverityScale(severity); // Draw the visual severity scale
          addSectionSeparator(); // Add a line to separate sections if necessary
        }
      }
    });

    if (form.title.includes('Patient Intake')) {
      addSectionSeparator();
      addLine('First Name', form.firstName || 'N/A');
      addLine('Middle Name', form.middleName || 'N/A');
      addLine('Last Name', form.lastName || 'N/A');
      addLine('Age', form.age || 'N/A');
      addLine('DOB', form.dob || 'N/A');
      addSectionSeparator();
      addLine('Address', form.address || 'N/A');
      addLine('City', form.city || 'N/A');
      addLine('State', form.state || 'N/A');
      addLine('Zip', form.zip || 'N/A');
      addSectionSeparator();
      addLine('Home Phone', form.homePhone || 'N/A');
      addLine('Cell Phone', form.cellPhone || 'N/A');
      addLine('Email', form.email || 'N/A');
      addLine('Emergency Contact', form.emergencyContact || 'N/A');
      addLine('Emergency Contact Phone', form.emergencyPhone || 'N/A');
      addSectionSeparator();
      addLine('What are your objectives in consulting our office?', form.objectives || 'N/A');
      // Add symptoms and effects
      if (form.symptoms) {
        addLine('Symptoms you have experienced in the past 6 months.', Object.keys(form.symptoms).filter(symptom => form.symptoms[symptom]).join(', ') || 'N/A');
      }
      if (form.effects) {
        // Map through effects array for nested effects display
        effects.forEach((effectCategory) => {
          const selectedOptions = effectCategory.options.filter(option => form.effects && form.effects[option]);
          if (selectedOptions.length > 0) {
            addLine(effectCategory.category, selectedOptions.join(', '));
          }
        });
      }
      addSectionSeparator();
      addLine('Is condition due to an accident?', form.isAccident || 'N/A');
      addLine('Type of Accident', form.accidentType || 'N/A')
      addLine('Other Type of Accident', form.otherAccidentType || 'N/A')
      addLine('Accident Date', form.accidentDate || 'N/A')
      addSectionSeparator();
      addLine('Insurance Type', form.insuranceType || 'N/A')
      addLine('Other Insurance Type', form.otherInsuranceType || 'N/A')
      addSectionSeparator();
      addLine('Would you like to get rid of the problem?', form.wantToSolveProblem || 'N/A')
      if (form.healthGoals) { 
      addLine('What are your overall Health Goals?', Object.keys(form.healthGoals).filter(healthGoal => form.healthGoals[healthGoal]).join(', ') || 'N/A')
      }
      addSectionSeparator();
      addLine('How did you hear about us?', form.refferalSource || 'N/A')
      addLine('Other way you heard about us', form.otherRefferralSource || 'N/A')
      addSectionSeparator();
      addLine('Additional Comments or Concerns', form.additionalComments || 'N/A')
      addSectionSeparator();
      addLine('I consent to treatment and agree to the terms and conditions', form.consentToTreatment ? 'Yes' : 'No')
        

    }

    if (form.title.includes('Sensation Survey')) {
      addLine('How frequently do you get headaches?', form.headacheFrequency || 'N/A');
      addLine('On a scale of 1-10, how bad are your headaches?', form.headacheSeverity || 'N/A');
      addSeverityScale(form.headacheSeverity);
      addSectionSeparator();
      addLine('Do your headaches affect your life?', form.headacheImpact || 'N/A');
      addLine('How often do you have neck pain?', form.neckPainFrequency || 'N/A');
      addLine('On a scale of 1-10, how bad is the neck pain?', form.neckPainSeverity || 'N/A');
      addSeverityScale(form.neckPainSeverity);
      addSectionSeparator();
      addLine('Does neck pain affect your life?', form.neckPainImpact || 'N/A');
      addLine('Do you get numbness/tingling/pain in hands?', form.handNumbnessFrequency || 'N/A');
      addLine('Does hand numbness affect your life?', form.handNumbnessImpact || 'N/A');
      addLine('How often do you have upper back pain?', form.upperBackPainFrequency || 'N/A');
      addLine('On a scale of 1-10, how bad is upper back pain?', form.upperBackPainSeverity || 'N/A');
      addSeverityScale(form.upperBackPainSeverity);
      addSectionSeparator();
      addLine('Does upper back pain affect your life?', form.upperBackPainImpact || 'N/A');
      addLine('Do you get chest numbness/tingling?', form.chestNumbnessFrequency || 'N/A');
      addLine('Does chest numbness affect your life?', form.chestNumbnessImpact || 'N/A');
      addLine('How often do you have lower back pain?', form.lowerBackPainFrequency || 'N/A');
      addLine('On a scale of 1-10, how bad is lower back pain?', form.lowerBackPainSeverity || 'N/A');
      addSeverityScale(form.lowerBackPainSeverity);
      addSectionSeparator();
      addLine('Does lower back pain affect your life?', form.lowerBackPainImpact || 'N/A');
      addLine('Do you get leg numbness/tingling?', form.legNumbnessFrequency || 'N/A');
      addLine('Does leg numbness affect your life?', form.legNumbnessImpact || 'N/A');
      addLine('Signature:', form.signature || 'N/A');
      addLine('Printed Name:', form.printName || 'N/A');
      addLine('Relationship to Patient', form.relationshipToPatient || 'N/A');
      addLine('Date Signed:', form.date || 'N/A');
    }

    if (form.title.includes('Consent to Chiropractic Care')) {
      addSectionSeparator();
      addLine('I fully understand these risks, the doctor has explained them to me and I consent to chiropractic care.', form.understandRisks ? 'Yes' : 'No');
      addLine('I consent to treatment', form.consentToTreatment ? 'Yes' : 'No');
      addSectionSeparator();
      addLine('Signature', form.signature || 'N/A');
      addLine('Date', form.date || 'N/A');
    }

    // Add styled footer
    doc.setDrawColor(128, 0, 128);
    doc.line(10, yPosition + 10, 200, yPosition + 10); 
    doc.setTextColor(128, 0, 128);
    doc.text('Generated by PosturePortal', 10, yPosition + 20);

    doc.save(`${form.title}.pdf`);
  };

  const renderSeverityScale = (severity) => {
    return (
      <div className="relative w-full h-4 bg-gray-200 rounded-full">
        <div
          className="absolute h-4 bg-blue-500 rounded-full"
          style={{ width: `${(severity / 10) * 100}%` }}
        />
        <div className="absolute top-0 left-0 w-full flex justify-between text-xs px-1">
          <span>1</span>
          <span>5</span>
          <span>10</span>
        </div>
      </div>
    );
  };

  // Render a form with questions and answers
  const renderForm = (form) => {
    if (form.title.includes('Patient Intake')) {
      return (
        <div>
          <h3 className="text-xl font-bold">Patient Intake</h3>
          <p><strong>Date:</strong> {form.date || 'N/A'}</p>          
          <hr className="my-6 border-purple-500" />
          <p><strong>First Name:</strong> {form.firstName || 'N/A'}</p>
          <p><strong>Middle Name:</strong> {form.middleName || 'N/A'}</p>
          <p><strong>Last Name:</strong> {form.lastName || 'N/A'}</p>
          <p><strong>Age:</strong> {form.age || 'N/A'}</p>
          <p><strong>Date of Birth:</strong> {form.dob || 'N/A'}</p>
          <hr className="my-6 border-purple-500" />
          <p><strong>Address:</strong> {form.address || 'N/A'}</p>
          <p><strong>City:</strong> {form.city || 'N/A'}</p>
          <p><strong>State:</strong> {form.state || 'N/A'}</p>
          <p><strong>Zip Code:</strong> {form.zip || 'N/A'}</p>
          <p><strong>Home Phone:</strong> {form.homePhone || 'N/A'}</p>
          <p><strong>Cell Phone:</strong> {form.cellPhone || 'N/A'}</p>
          <p><strong>Email:</strong> {form.email || 'N/A'}</p>
          <p><strong>Emergency Contact:</strong> {form.emergencyContact || 'N/A'}</p>
          <p><strong>Emergency Contact Phone:</strong> {form.emergencyPhone || 'N/A'}</p>
          <hr className="my-6 border-purple-500" />
          <p><strong>What are your objectives in consulting our office?</strong> {form.objectives || 'N/A'}</p>
          <hr className="my-6 border-purple-500" />
          <p><strong>Symptoms you have experienced in the past 6 months:</strong> {Object.keys(form.symptoms).filter(symptom => form.symptoms[symptom]).join(', ') || 'N/A'}</p>
          <p><strong>Effects:</strong></p>
          <ul>
          {effects.map((effectCategory) => (
              <li key={effectCategory.category}>
              <strong>{effectCategory.category}</strong>
              <ul>
                  {effectCategory.options
                  .filter(option => form.effects && form.effects[option]) // Filter to show only selected effects
                  .map(option => (
                      <li key={option}>{option}</li>
                  ))}
              </ul>
              </li>
          ))}
          </ul>          
          <hr className="my-6 border-purple-500" />
          <p><strong>Is condition due to an accident?</strong> {form.isAccident || 'N/A'}</p>
          <p><strong>Type of Accident:</strong> {form.accidentType || 'N/A'}</p>
          <p><strong>Other Type of Accident:</strong> {form.otherAccidentType || 'N/A'}</p>
          <p><strong>Accident Date:</strong> {form.accidentDate || 'N/A'}</p>
          <hr className="my-6 border-purple-500" />
          <p><strong>Insurance Type:</strong> {form.insuranceType || 'N/A'}</p>
          <p><strong>Would you like to get rid of the problem?</strong> {form.wantToSolveProblem || 'N/A'}</p>
          <p><strong>What are your overall Health Goals?'</strong> {Object.keys(form.healthGoals).filter(healthGoal => form.healthGoals[healthGoal]).join(', ') || 'N/A'}</p>
          <hr className="my-6 border-purple-500" />
          <p><strong>How did you hear about us?'</strong> {form.refferalSource || 'N/A'}</p>
          <p><strong>Other way you heard about us'</strong> {form.otherRefferralSource || 'N/A'}</p>
          <hr className="my-6 border-purple-500" />
          <p><strong>Additional Comments or Concerns'</strong> {form.additionalComments || 'N/A'}</p>
          <hr className="my-6 border-purple-500" />

          <p><strong>I consent to treatment and agree to the terms and conditions:</strong> {form.consentToTreatment ? 'Yes' : 'No'}</p>
        </div>
      );
    }

    if (form.title.includes('Sensation Survey')) {
      return (
        <div>
          <h3 className="text-xl font-bold">Sensation Survey</h3>

          <p><strong>Date:</strong> {form.date || 'N/A'}</p>          

          <hr className="my-6 border-purple-500" />

          <p><strong>1. How frequently do you get headaches?</strong> {form.headacheFrequency || 'N/A'}</p>
          <p><strong>2. On a scale of 1-10 how bad are they when they come?</strong> {form.headacheSeverity || 'N/A'}</p>
          {renderSeverityScale(form.headacheSeverity)}

          <p><strong>3. Do they negatively affect your life in any way? If yes, please explain.</strong> {form.headacheImpact || 'N/A'}</p>

          <hr className="my-6 border-purple-500" />

          <p><strong>4. How often do you have pain, stiffness or soreness in your neck?</strong> {form.neckPainFrequency || 'N/A'}</p>
          <p><strong>5. On a scale of 1-10 how bad is the discomfort?</strong> {form.neckPainSeverity || 'N/A'}</p>
          {renderSeverityScale(form.neckPainSeverity)}

          <p><strong>6. Does neck pain affect your life in any way?</strong> {form.neckPainImpact || 'N/A'}</p>

          <p><strong>7. Do you ever get any numbness/tingle/pain in your arms/hands?</strong> {form.handNumbnessFrequency || 'N/A'}</p>
          <p><strong>8. Does hand numbness affect your life in any way?</strong> {form.handNumbnessImpact || 'N/A'}</p>

          <hr className="my-6 border-purple-500" />

          <p><strong>9. How often do you have pain, stiffness or soreness in your mid to upper back?</strong> {form.upperBackPainFrequency || 'N/A'}</p>
          <p><strong>10. On a scale of 1-10 how bad is the discomfort?</strong> {form.upperBackPainSeverity || 'N/A'}</p>
          {renderSeverityScale(form.upperBackPainSeverity)}

          <p><strong>11. Does upper back pain affect your life?</strong> {form.upperBackPainImpact || 'N/A'}</p>

          <p><strong>12. Do you get chest numbness/tingling?</strong> {form.chestNumbnessFrequency || 'N/A'}</p>
          <p><strong>13. Does chest numbness affect your life?</strong> {form.chestNumbnessImpact || 'N/A'}</p>

          <hr className="my-6 border-purple-500" />

          <p><strong>14. How often do you have lower back pain?</strong> {form.lowerBackPainFrequency || 'N/A'}</p>
          <p><strong>15. How bad is lower back pain on a scale of 1-10?</strong> {form.lowerBackPainSeverity || 'N/A'}</p>
          {renderSeverityScale(form.lowerBackPainSeverity)}

          <p><strong>16. Does lower back pain affect your life?</strong> {form.lowerBackPainImpact || 'N/A'}</p>

          <p><strong>17Do you get leg numbness?</strong> {form.legNumbnessFrequency || 'N/A'}</p>
          <p><strong>Does leg numbness affect your life?</strong> {form.legNumbnessImpact || 'N/A'}</p>

          <hr className="my-6 border-purple-500" />

          <p><strong>Signature:</strong> {form.signature || 'N/A'}</p>
          <p><strong>Printed Name:</strong> {form.printName || 'N/A'}</p>
          <p><strong>Relationship to Patient:</strong> {form.relationshipToPatient || 'N/A'}</p>
          <p><strong>Date Signed:</strong> {form.date || 'N/A'}</p>
        </div>
      );
    }

    if (form.title.includes('Consent to Chiropractic Care')) {
      return (
        <div>
          <h3 className="text-xl font-bold">Consent to Chiropractic Care</h3>
          <p><strong>Date:</strong> {form.date || 'N/A'}</p>          
          <hr className="my-6 border-purple-500" />
          <p><strong>I fully understand these risks, the doctor has explained them to me and I consent to chiropractic care.</strong> {form.understandRisks ? 'Yes' : 'No'}</p>
          <p><strong>Do you consent to treatment?</strong> {form.consentToTreatment ? 'Yes' : 'No'}</p>
          <hr className="my-6 border-purple-500" />
          <p><strong>Signature:</strong> {form.signature || 'N/A'}</p>
          <p><strong>Date:</strong> {form.date || 'N/A'}</p>
        </div>
      );
    }

    return <div>Form data not recognized</div>;
  };

  const dashboardMenuItems = [
    {
      key: '1',
      label: (
        <Link to={`/view-forms/${patientId}`}><FileDoneOutlined /> View Forms</Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to={`/upload-medical-records/${patientId}`}><FolderOutlined /> Medical Records</Link>
      ),
    },
    {
      key: '3',
      label: (
        <Link to={`/patient-notes/${patientId}`}><EditOutlined /> Patient Notes</Link>
      ),
    },
    {
      key: '4',
      label: (
        <Link to={`/vitals/${patientId}`}><HeartOutlined /> Vitals</Link>
      ),
    },
    {
      key: '5',
      label: (
        <Link to={`/prescription-management/${patientId}`}><MedicineBoxOutlined /> Prescriptions</Link>
      ),
    },
  ];

  const formViewerMenuItems = [
    {
      key: '1',
      label: (
        <Link to={`/patient-forms/${patientId}`}><FileAddOutlined /> Fill Out Patient Forms</Link>
      ),
    },
  ];

  const handleFillOutForms = (id) => {
    navigate(`/patient-forms/${patientId}`)
  }

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-6xl mx-auto">
      <Breadcrumb
        className='text-xl font-bold'
        items={[
          {
            title: <Link to={`/patients`}><TeamOutlined style={{ fontSize: '1.25rem' }} /> <span className="text-sm sm:text-base md:text-lg">Patient List</span></Link>,
          },
          {
            title: 
              <Link to={`/dashboard/${patientId}`}><IdcardOutlined style={{ fontSize: '1.25rem' }} /> <span className="text-sm sm:text-base md:text-lg">Dashboard</span></Link>,
              menu: { items: dashboardMenuItems },
          },
          {
            title: 
              <Link to={`/view-forms/${patientId}`}><FileDoneOutlined style={{ fontSize: '1.50rem' }} className='text-indigo-900'/> <span className="text-2xl text-indigo-900">View Forms</span></Link>,
              menu: { items: formViewerMenuItems },
          },
        ]}
      />
      <div className="flex justify-between items-center mb-6">
        {/* Back to Dashboard */}
        {/* <Link
          to={`/dashboard/${patientId}`}
          className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700"
        >
          Back to Dashboard
        </Link> */}
        
        {/* <h1 className="text-3xl font-bold text-center text-indigo-900 flex-grow">
          Patient Forms
        </h1> */}

        {/* <button
          onClick={() => navigate(`/patient-forms/${patientId}`)}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Fill Out Patient Forms
        </button> */}
      </div>

      {forms.length === 0 ? (
        <div className="flex flex-col items-center mb-6">
          <p className="text-center text-xl text-gray-600 mb-4">No completed forms available...</p>
          <button 
            onClick={() => handleFillOutForms(patientId)}
            className="text-xl font-bold text-indigo-900"
          >
            ... <FileAddOutlined className='text-indigo-900' style={{ fontSize: '1.50rem' }}/> Fill Out Forms
          </button>
        </div>
      ) : (
        <div className="space-y-6">
          {forms.map((form) => (
            <div key={form.id} className="p-6 bg-gray-100 rounded-lg shadow-md">
              {renderForm(form)}

              <button
                onClick={() => exportToPDF(form)}
                className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
              >
                Export to PDF
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FormViewerPage;