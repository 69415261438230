import React, { useState, useEffect } from 'react';
import { db, model } from '../../firebase';
import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import ReactMarkdown from 'react-markdown';
import { Avatar, Layout, Button, List, Input, message, Drawer } from 'antd';
import { DeleteOutlined, MenuOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import DefaultAvatar from '../../assets/default-profile-pic.png'
import Logo from '../../assets/logo.png'

const { Header, Content } = Layout;
const { TextArea } = Input;

const AskQuestion = ({ currentUserData }) => {
  const { currentUser } = useAuth();
  const [question, setQuestion] = useState('');
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [currentSessionId, setCurrentSessionId] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const systemPrompt = "You are a helpful chatbot here to provide knowledge and information to chiropractors. You are an expert in the field. Please be factual and professional. Ask clarifying questions whenever you are unsure. Be direct and succinct.";

  useEffect(() => {
    const fetchSessions = async () => {
      if (!currentUser) return;
      const querySnapshot = await getDocs(collection(db, `users/${currentUser.uid}/chatSessions`));
      const sessionsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      // Sort sessions by createdAt in descending order
      sessionsData.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
      setSessions(sessionsData);
    };

    fetchSessions();
  }, [currentUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!model) throw new Error("Model is not initialized");

      const fullQuestion = `${systemPrompt}\nUser: ${question}`;
      const result = await model.generateContent(fullQuestion);
      const text = result.response.text();

      const newResponse = { question, response: text };
      setResponses((prev) => [...prev, newResponse]);
      setQuestion('');

      await addDoc(collection(db, `users/${currentUser.uid}/chatSessions`), {
        messages: [...responses, newResponse],
        createdAt: new Date()
      });
    } catch (error) {
      console.error('Error generating response:', error);
      setResponses((prev) => [...prev, { question, response: 'Error generating response. Please try again.' }]);
    } finally {
      setLoading(false);
    }
  };

  const loadSession = async (sessionId) => {
    const sessionDoc = await getDocs(collection(db, `users/${currentUser.uid}/chatSessions`));
    const sessionData = sessionDoc.docs.find(doc => doc.id === sessionId);
    if (sessionData) {
      setCurrentSessionId(sessionId);
      setResponses(sessionData.data().messages);
    }
  };

  const startNewSession = () => {
    setCurrentSessionId(null);
    setResponses([]);
    setQuestion('');
  };

  const deleteSession = async (sessionId) => {
    try {
      await deleteDoc(doc(db, `users/${currentUser.uid}/chatSessions`, sessionId));
      setSessions(sessions.filter(session => session.id !== sessionId));
      message.success('Session archived successfully');
    } catch (error) {
      console.error('Error archiving session:', error);
      message.error('Failed to archive session');
    }
  };

  return (
    <Layout style={{ height: '85vh' }}>
      <Header className="bg-white dark:bg-gray-800 border-b border-gray-200 flex items-center px-4">
        <Button type="text" icon={<MenuOutlined />} onClick={() => setDrawerVisible(true)} className="mr-4">
          Previous Sessions
        </Button>
        <div className="flex items-center text-indigo-900">
          <QuestionCircleOutlined className="mr-2 text-xl" />
          <h2 className="text-xl font-bold">Ask a Question</h2>
        </div>
      </Header>
      <Content className="p-6 bg-white">
        <div className="overflow-y-auto max-h-[calc(100vh-200px)]">
          {responses.map((item, index) => (
            <div key={index} className="mb-4">
              <br></br>
              <p className="font-semibold">
                <Avatar 
                    src={currentUserData.photoURL || DefaultAvatar }
                    size="medium" 
                    className="mr-2" 
                    alt={`${currentUserData.firstName} ${currentUserData.lastName}`}
                  />Me:</p>
              <p>{item.question}</p>
              <br></br>
              <p className="font-semibold">
              <Avatar 
                    src={ Logo }
                    size="large" 
                    className="mr-2" 
                    alt="logo"
                  />PosturePortal:</p>
              <ReactMarkdown>{item.response}</ReactMarkdown>
            </div>
          ))}
        </div>
        <form onSubmit={handleSubmit} className="mt-4">
          <TextArea
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Type your question here..."
            required
            rows={4}
            className="mb-2"
          />
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={loading}
          >
            {loading ? 'Loading...' : 'Ask'}
          </Button>
        </form>
      </Content>
      <Drawer
        title="Previous Sessions"
        placement="left"
        closable={true}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        width={300}
      >
        <Button type="primary" block onClick={startNewSession} className="mb-4">
          New Session
        </Button>
        <List
          dataSource={sessions}
          renderItem={session => (
            <List.Item
              actions={[
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => deleteSession(session.id)}
                />
              ]}
              onClick={() => loadSession(session.id)}
              className="cursor-pointer"
            >
              {session.messages.length > 0 ? session.messages[0].question.slice(0, 20) + '...' : 'No messages'}
            </List.Item>
          )}
        />
      </Drawer>
    </Layout>
  );
};

export default AskQuestion;