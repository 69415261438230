import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Form, Input, Button, Upload, Select, message, Avatar } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const EditProfile = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    displayName: '',
    photoURL: '',
    firstName: '',
    lastName: '',
    role: ''
  });
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      if (userDoc.exists()) {
        setUserData(userDoc.data());
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleFileChange = (info) => {
    if (info.file.status === 'done') {
      setFile(info.file.originFileObj);
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    setUploading(true);
    const storage = getStorage();
    const storageRef = ref(storage, `profilePictures/${currentUser.uid}/${file.name}`);
    await uploadBytes(storageRef, file);
    const photoURL = await getDownloadURL(storageRef);

    setUserData((prevData) => ({
      ...prevData,
      photoURL,
    }));

    setUploading(false);
    message.success('Photo uploaded successfully');
  };

  const handleSave = async () => {
    const userRef = doc(db, 'users', currentUser.uid);
    await updateDoc(userRef, userData);
    message.success('Profile updated successfully');
    navigate('/'); // Redirect to home page after saving
  };

  return (
    <div className="max-w-2xl dark:bg-gray-800 mx-auto p-6 bg-white rounded-lg shadow-md mt-8">
      <h1 className="text-3xl font-bold mb-6">Edit Profile</h1>
      <Form layout="vertical">
        <Form.Item label="First Name">
          <Input
            value={userData.firstName}
            onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Last Name">
          <Input
            value={userData.lastName}
            onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Display Name">
          <Input
            value={userData.displayName}
            onChange={(e) => setUserData({ ...userData, displayName: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Role">
          <Select
            value={userData.role}
            onChange={(value) => setUserData({ ...userData, role: value })}
          >
            <Option value="admin">Admin</Option>
            <Option value="managingDoctor">Managing Doctor</Option>
            <Option value="doctor">Doctor</Option>
            <Option value="frontOffice">Front Office</Option>
            <Option value="massage">Massage</Option>
            <Option value="laser">Laser</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Profile Picture">
          <Upload
            beforeUpload={() => false}
            onChange={handleFileChange}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
          {userData.photoURL && (
            <Avatar src={userData.photoURL} size={64} className="mt-2" />
          )}
          <Button
            onClick={handleUpload}
            disabled={uploading || !file}
            className="mt-2"
          >
            {uploading ? 'Uploading...' : 'Upload Photo'}
          </Button>
        </Form.Item>
        <Button type="primary" onClick={handleSave}>
          Save Profile
        </Button>
      </Form>
    </div>
  );
};

export default EditProfile;