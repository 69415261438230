import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { auth } from '../../firebase'; // Import Firebase auth

const InternalMessagesThread = ({ thread }) => {
  const [currentUserEmail, setCurrentUserEmail] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUserEmail(user.email);
      } else {
        setCurrentUserEmail('');
      }
    });

    return () => unsubscribe();
  }, []);

  if (!thread || thread.length === 0) {
    return <div className="h-full flex items-center justify-center text-gray-500">Select a message to view the thread</div>;
  }

  console.log('Rendering thread:', thread);

  return (
    <div className="internal-messages-thread dark:bg-gray-800 h-full overflow-y-auto p-6">
      <h2 className="text-2xl font-bold dark:text-white text-gray-800 mb-4">{thread[0].subject}</h2>
      {thread.map((message, index) => (
        <div key={message.id} className="message bg-gray-50 rounded-lg p-4 mb-4">
          <div className="message-header flex justify-between items-center mb-2">
            <span className="font-medium text-gray-900">{message.sender === currentUserEmail ? 'You' : message.sender}</span>
            <span className="text-sm text-gray-500">
              {message.timestamp && message.timestamp.toDate 
                ? message.timestamp.toDate().toLocaleString() 
                : 'Date unavailable'}
            </span>
          </div>
          <p className="text-gray-800 whitespace-pre-wrap">{message.content}</p>
        </div>
      ))}
    </div>
  );
};

InternalMessagesThread.propTypes = {
  thread: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    sender: PropTypes.string.isRequired,
    timestamp: PropTypes.object,
    content: PropTypes.string.isRequired,
  })),
};

export default InternalMessagesThread;